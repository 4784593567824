import { createApp } from 'vue'
import App from './App.vue'
// import Vuetify from 'vuetify'
import router from './router'
import mixins from './mixins'
// import VueGoogleMaps from '@fawmi/vue-google-maps'
import IconBase from './components/IconBase.vue'
import store from './store'

// createApp(App).use(router).mount('#app')

const app = createApp(App)
app.use(router)
app.use(store)
// app.use(VueGoogleMaps, {
//     load:{
//         key: 'AIzaSyCkUOdZ5y7hMm0yrcCQoCvLwzdM6M8s5qk',
//     },
// })
// app.use(Vuetify)
app.mixin(mixins);
app.component('icon-base',IconBase);
app.mount('#app')