<template>
  <div id="loading" v-if="isLoading">
    <img :src="require(`@/assets/loading.gif`)" alt="loading">
  </div>

  <!-- modal -->
  <div v-if="onModalchk">
    <div class="black-bg">
        <div class="white-bg">
            <h4 class="modal-title">수정</h4>

            <div class="modal-inner">
                <div>
                    <label for="title">제목</label>
                    <input class="modal-input-title" v-model="tempTitle"/>
                </div>
                <div>
                    <label for="origintitle">원제</label>
                    <input class="modal-input-title" v-model="tempOriginTitle"/>
                </div>
                <div>
                    <label for="author">저자</label>
                    <input class="modal-input" v-model="tempAuthor"/>
                </div>
                <div>
                    <label for="translator">번역자</label>
                    <input class="modal-input" v-model="tempTranslator"/>
                </div>
                <div>
                    <label for="publisher">출판사</label>
                    <input class="modal-input" v-model="tempPublisher"/>
                </div>
                <div class="modal-button-wrapper">
                    <button class="modal-button" @click="onEdit()">SAVE</button>
                    <button class="modal-button" @click="onModal(false)">CLOSE</button>
                </div>
            </div>
        </div>
    </div>
  </div>

  <!-- content -->
  <div class="content">
    <detail-contents :content="this.bookDetail" @update="onModal(true)"/>
  </div>
</template>

<script>

import axios from 'axios';
import DetailContents from '../components/DetailContents.vue'

export default {
  name: 'Detail',
  data () {
    return {
        isLoading: false,
        id:0,
        bookDetail: [],
        onModalchk:false,
        tempTitle:'',
        tempOriginTitle:'',
        tempAuthor:'',
        tempTranslator:'',
        tempPublisher:'',
        tempId:'',
    }
  },
  components:{
    DetailContents,
  },
  created(){
    this.id = this.$route.query.id;
    this.getBookDetail();
  },
  mounted() {
    // window.kakao && window.kakao.maps
    //   ? this.initMap(this.bookDetail.latitude, this.bookDetail.longitude)
    //   : this.addKakaoMapScript();
  },
  methods: {
    async getBookDetail(){
        
        this.isLoading = true;
        // var url = 'https://97b419b6-0d10-4926-984c-11e10ef1540c.mock.pstmn.io/detail';
        var url = 'https://libraryapi.yirah.kr/detail';
        var bookDetail = await axios.get(url, {
            params: {
                id: this.id,
            }
        }).catch(function (error) {
            console.log(error);
        });

        // 1.5초 기다리기
        setTimeout(1500);

        // 스크롤을 상단으로
        window.scrollTo(0,0);

        this.bookDetail = bookDetail.data[0];
        this.isLoading = false;

        // return bookDetail.data[0];

        // window.kakao && window.kakao.maps
        // ? this.initMap(this.bookDetail.latitude, this.bookDetail.longitude)
        // : this.addKakaoMapScript();
    },
    onModal(checker){

      if(checker){
        this.tempId = this.bookDetail.id;
        this.tempTitle = this.bookDetail.title;
        this.tempOriginTitle = this.bookDetail.origintitle;
        this.tempAuthor = this.bookDetail.author;
        this.tempTranslator = this.bookDetail.translator;
        this.tempPublisher = this.bookDetail.publisher;
      } else{
        this.tempId = '';
        this.tempTitle = '';
        this.tempOriginTitle = '';
        this.tempAuthor = '';
        this.tempTranslator = '';
        this.tempPublisher = '';
      }

      this.onModalchk = checker;
    },
    async onEdit(){
        let id = this.tempId;
        let title = this.tempTitle;
        let origintitle = this.tempOriginTitle;
        let author = this.tempAuthor;
        let translator = this.tempTranslator;
        let publisher = this.tempPublisher;

        let url = 'https://libraryapi.yirah.kr/detail/'+id;
        let data = {
            title:title,
            origintitle:origintitle,
            author:author,
            translator:translator,
            publisher:publisher
        };

        await axios.put(url, JSON.stringify(data), {
          headers: {
            "Content-Type": `application/json`,
          },
        }).catch(function (error) {
            console.log(error);
        });
        // .then((res) => {
        //   console.log(res);
        // });
        
        this.onModal(false);
        this.$router.go();
    },
  }
}

</script>