<template>

  <div id="loading" v-if="isLoading">
    <img :src="require(`@/assets/loading.gif`)" alt="loading">
  </div>

  <!-- modal -->
  <div v-if="modalStat==1">
    <div class="black-bg">
        <div class="white-bg">
            <h4 class="modal-title">추가</h4>

            <div class="modal-inner">
                <div>
                    <label for="isbn">ISBN</label>
                    <input class="modal-input" v-model="tempISBN"/>
                </div>
                <div>
                    <label for="findstat">책 상태</label>
                    <!-- <select class="modal-select" @change="onStatChange($event)">
                        <option v-for="option in bookFindStat" :key="option" :value="option.value">{{option.text}}</option>
                    </select> -->
                    <div class="findstat-inner">
                        <div class="form-check form-switch">
                            <!-- <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" v-model="checked"> -->
                            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" v-model="tempFindStat">
                            <label class="form-check-label" for="flexSwitchCheckDefault">최상으로 찾기</label>
                        </div>
                    </div>
                </div>
                <div class="modal-button-wrapper">
                    <button class="modal-button" @click="onCreate()">SAVE</button>
                    <button class="modal-button" @click="onModal(0, 0, null, null, 0)">CLOSE</button>
                </div>
            </div>
        </div>
    </div>
  </div>
  <div v-else-if="modalStat==2">
    <div class="black-bg">
        <div class="white-bg">
            <h4 class="modal-title">수정</h4>

            <div class="modal-inner">
                <div>
                    <label for="title">제목</label>
                    <input class="modal-input-title" v-model="tempTitle"/>
                </div>
                <!-- <div>
                    <label for="isbn">ISBN</label>
                    <input class="modal-input" v-model="tempISBN"/>
                </div> -->
                <div>
                    <label for="findstat">책 상태</label>
                    <!-- <select class="modal-select" @change="onStatChange($event)">
                        <option v-for="option in bookFindStat" :key="option" :value="option.value">{{option.text}}</option>
                    </select> -->
                    <div class="findstat-inner">
                        <div class="form-check form-switch">
                            <!-- <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" v-model="checked"> -->
                            <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckDefault" v-model="tempFindStat">
                            <label class="form-check-label" for="flexSwitchCheckDefault">최상으로 찾기</label>
                        </div>
                    </div>
                </div>
                <div class="modal-button-wrapper">
                    <button class="modal-button" @click="onEdit()">SAVE</button>
                    <button class="modal-button" @click="onModal(0, 0, null, null, 0)">CLOSE</button>
                </div>
            </div>
        </div>
    </div>
  </div>
  <div v-else-if="modalStat==3">
    <div class="black-bg">
        <div class="white-bg">
            <h4 class="modal-title">삭제</h4>

            <div class="modal-inner">
                <div class="modal-button-wrapper">
                    <button class="modal-button" @click="onRemove()">YES</button>
                    <button class="modal-button" @click="onModal(0, 0, null, null, 0)">NO</button>
                </div>
            </div>
        </div>
    </div>
  </div>

  <!-- content -->
  <div class="content">
    <div class="search-block">
      <div class="search">
          <select @change="onChange($event)">
            <option v-for="option in options" :key="option" :value="option.value">{{option.text}}</option>
          </select>
          <input class="search-box" v-model="search"/>
      </div>
    </div>

    <div class="table-content">
        <div class="table-content-inner">
            <div class="cardtr" v-for="list in paginatedData" :key="list.id">
                <div class="cardtr-inner">
                    <div class="titletd">
                        <div class="titletd-inner">
                            <div>{{list.title}}</div>
                            <div>{{list.isbn}}</div>
                        </div>
                    </div>
                    <div class="stattd">
                        <div class="stattd-inner">
                            {{list.findstat==1?'최상':'중'}}
                        </div>
                    </div>
                    <div class="icontd">
                        <div v-if="this.$store.state.adminMode">
                            <img @click="onModal(2, list.id, list.title, list.isbn, list.findstat)" :src="require(`@/assets/icons/pencil-square.svg`)" class="icon-pencil-square">
                        </div>
                        <div v-if="this.$store.state.adminMode">
                            <img @click="onModal(3, list.id, null, null, 0)" :src="require(`@/assets/icons/trash.svg`)" class="icon-trash">
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div v-if="search==''">
      <div class="notion">
        <h5>총 {{searchedTotalPage}}개의 데이터가 검색되었습니다</h5>
      </div>

      <div id="paging">
        <a v-if="page>1" class="prev" :href="'./seek?page=1'">처음</a>
        <a v-if="page>1" class="prev" :href="'./seek?page='+this.pre">◀ 이전</a>
        <a v-for="i in paginationMake.list" :key="i" class="num" :href="'./seek?page='+i">{{i}}</a>
        <a v-if="page<total" class="prev" :href="'./seek?page='+this.next">다음 ▶</a>
        <a v-if="page<total" class="prev" :href="'./seek?page='+this.total">마지막</a>
      </div>
    </div>

    <div v-if="this.$store.state.adminMode">
      <img @click="onModal(1, 0, null, null, 0)" :src="require(`@/assets/icons/plus-circle-fill.svg`)" class="icon-plus">
    </div>
    
    <!-- <icon-base viewBox="0 0 50 50" width="40" height="40" iconColor="rgba(142,98,72,0.8)" icon-name="plus"
                    @click="onModal(1, 0)">
    <icon-plus/>
    </icon-base> -->

  </div>

</template>

<script>

import axios from 'axios';
// import IconPencilSquare from '../components/icons/IconPencilSquare.vue'
// import IconTrash from '../components/icons/IconTrash.vue'
// import IconPlus from '../components/icons/IconPlus.vue'

export default {
  name: 'Seek',
  data () {
    return {
      isLoading: false,
      options: [
                {text: '제목', value: 'title'},
                {text: 'ISBN', value: 'isbn'},
                ],
      setOption:'title',
      modalStat: 0,
      tempId: 0,
      tempTitle: '',
      tempISBN:'',
      tempFindStat:false,
      page: 1,
      bookList: [],
      search:'',
      searchData: [],
      total: 1,
      pre:0,
      next:0,
      limit: 20,
      block: 5,
      searchedTotalPage: 0,
    }
  },
//   components:{
//       IconPencilSquare,
//       IconTrash,
//       IconPlus,
//   },
  created(){
    this.getList();
  },
  mounted(){
    this.getParameter();
    this.getTotal();
    this.getPreNext()
  },
  updated(){
      this.getParameter();
      this.getTotal();
      this.getPreNext()
  },
  methods: {
    async getList(){
      
      this.isLoading = true;
      this.bookList = await this.$api('https://libraryapi.yirah.kr/seek', "GET");

      this.searchedTotalPage = this.bookList.length;
      this.isLoading = false;
    },
    getTotal(){
      this.total = Math.ceil(this.bookList.length/this.limit);
    },
    async getParameter(){
      if(this.$route.query.page==null){
        this.page = 1;
      } else{
        this.page = this.$route.query.page;
      }
    },
    onChange(event) {
      this.setOption = event.target.value;
    },
    // onStatChange(event) {
    //   this.tempFindStat = parseInt(event.target.value);
    // },
    // 일부 페이지에서 문자열 연산으로 페이징 하는 현상 수정
    getPreNext(){
    
      let number = this.page;
      number *= 1;

      this.pre = number-1;
      this.next = number+1;
  
    },
    onModal(modalStat, id, title, isbn, findStat){
        if(modalStat==1){
        // Create, 추가 모달창 로드
            this.tempISBN = '';
            this.modalStat = 1;
        } else if(modalStat==2){
        // Edit, 수정 모달창 로드
            this.tempId = id;
            this.tempTitle = title;
            this.tempISBN = isbn;
            
            if(findStat == 1){
                this.tempFindStat = true;
            } else{
                this.tempFindStat = false;
            }

            this.modalStat = 2;

        } else if(modalStat==3){
        // Remove, 삭제 모달창 로드
            this.tempId = id;
            this.modalStat = 3;
        } else{
            this.modalStat = 0;
        }

        window.scrollTo(0,0);
    },
    async onCreate(){

        let isbn = this.tempISBN;
        let findstat = 0;

        if(this.tempFindStat){
            findstat = 1;
        }

        let url = 'https://libraryapi.yirah.kr/seek';
        let data = {
            isbn: isbn,
            findstat:findstat,
        }

        await axios.post(url, JSON.stringify(data), {
          headers: {
            "Content-Type": `application/json`,
          },
        }).catch(function (error) {
            console.log(error);
        });
        // .then((res) => {
        //   console.log(res);
        // });

        this.onModal(0, 0, null, null, 0);
        this.$router.go();

    },
    async onEdit(){
        let id = this.tempId;
        let title = this.tempTitle;
        // let isbn = this.tempISBN;
        let findstat = 0;

        if(this.tempFindStat){
            findstat = 1;
        }

        let url = 'https://libraryapi.yirah.kr/seek/'+id;
        let data = {
            title:title,
            // isbn:isbn,
            findstat:findstat,
        };

        await axios.put(url, JSON.stringify(data), {
          headers: {
            "Content-Type": `application/json`,
          },
        }).catch(function (error) {
            console.log(error);
        });
        // .then((res) => {
        //   console.log(res);
        // });
        
        this.onModal(0, 0, null, null, 0);
        this.$router.go();
    },
    async onRemove(){
        let id = this.tempId;

        let url = 'https://libraryapi.yirah.kr/seek/'+id;

        await axios.delete(url
        ).catch(function (error) {
            console.log(error);
        });
        // .then((res) => {
        //   console.log(res);
        // });

        this.onModal(0, 0, null, null, 0);
        this.$router.go();
    },
  },
  computed: {

    paginationMake () {

        let listLeng = this.bookList.length,
            listSize = this.limit,
            page = Math.floor(listLeng / listSize);
        if (listLeng % listSize > 0) page += 1;

        page;

        const total = this.bookList.length
        const totalPage = Math.ceil(total / this.limit)
        let currentPage = this.page
        const first =
          currentPage > 1 ? parseInt(currentPage, 10) - parseInt(1, 10) : null
        const end =
          totalPage !== currentPage
            ? parseInt(currentPage, 10) + parseInt(1, 10)
            : null
 
        
        let startIndex = (Math.ceil(currentPage / this.block) - 1) * this.block + 1
        let endIndex =
          startIndex + this.block > totalPage ? totalPage : startIndex + this.block - 1
        let list = []

        for (let index = startIndex; index <= endIndex; index++) {
          list.push(index)
        }

        return { first, end, list, currentPage }
    },
    paginatedData () {
      const start = (this.page-1) * this.limit,
            end = start + this.limit;

      let searchData = [];

      if(this.search&&this.setOption=="title"){
        searchData = this.bookList.filter((data)=> {
          return data.title.toLowerCase().includes(this.search.toLowerCase())
        });

        return searchData;

      } else if(this.search&&this.setOption=="isbn"){

        searchData = this.bookList.filter((data)=> {
          return data.isbn.toLowerCase().includes(this.search.toLowerCase())
        });

        return searchData;

      } else{
        // 검색어가 없다면 전체

        return this.bookList.slice(start, end);
      }
      
    },
  }
}

</script>