import { createRouter, createWebHistory } from 'vue-router'
import PaginatedList from '../views/PaginatedList.vue'
import Seek from '../views/Seek.vue'
import Detail from '../views/Detail.vue'
import Transfer from '../views/Transfer.vue'


const routes = [
  {
    // path: '/yirahstudy_test',
    path: '/',
    name: 'PaginatedList',
    component: PaginatedList
  },
  {
    path: '/detail',
    name: 'Detail',
    component: Detail
  },
  {
    path: '/seek',
    name: 'Seek',
    // beforeEnter: () => {
    //   window.location.replace("https://yirah.kr/yirahstudy/aladintracker.php")
    // },
    component: Seek
  },
  {
    path: '/transfer',
    name: 'Transfer',
    // beforeEnter: () => {
    //   window.location.replace("https://yirah.kr/yirahstudy/transferbarcode.html")
    //   window.location.replace("../views/insertbarcode.html")
    // },
    component: Transfer
  },
  // {
  //   path: '/about',
  //   name: 'About',
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  // }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
