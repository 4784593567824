<template>

  <!-- <div id="loading" v-if="isLoading">
    <img :src="require(`@/assets/loading.gif`)" alt="loading">
  </div> -->

  <!-- modal -->
  <div v-if="modalStat == true">
    <div class="black-bg">
        <div class="white-bg">
            <h4 class="modal-title">추가</h4>

            <div class="modal-inner">
                <div>
                    <label for="isbn">ISBN</label>
                    <input class="modal-input" v-model="tempISBN"/>
                </div>
                <div>
                    <label for="loacation">장소</label>
                    <div class="register-modal-wrapper">
                      <select @change="onChangeInModal($event)">
                        <option v-for="option in locationOption" :key="option" :value="option.id">{{ option.address }}</option>
                      </select>
                    </div>
                </div>
                <div class="modal-button-wrapper">
                    <button class="modal-button" @click="sendRegister()">SAVE</button>
                    <button class="modal-button" @click="onModal(false)">CLOSE</button>
                </div>
            </div>
        </div>
    </div>
  </div>

  <!-- content -->
  <div class="content">
    <div class="search-block">
      <div class="search">
          <select @change="onChange($event)">
            <option v-for="option in options" :key="option" :value="option.value">{{option.text}}</option>
          </select>
          <input class="search-box" v-model="search"/>
      </div>
    </div>

    <div class="table-content">
      <div class="book-card" :key="book.no" v-for="book in paginatedData">
        <a v-on:click="goToDetail(book.id)">

          <div class="img-td">
            <img :src="'/img/'+book.isbn+'.jpg'" :alt="book.title">
          </div>
        </a>
      </div>

    </div>

    <div v-if="search==''">
      <div class="notion">
        <h5>총 {{searchedTotalPage}}개의 데이터가 검색되었습니다</h5>
      </div>

      <div id="paging">
        <a v-if="page>1" class="prev" :href="'./?page=1'">처음</a>
        <a v-if="page>1" class="prev" :href="'./?page='+this.pre">◀ 이전</a>
        <a v-for="i in paginationMake.list" :key="i" class="num" :href="'./?page='+i">{{i}}</a>
        <a v-if="page<total" class="prev" :href="'./?page='+this.next">다음 ▶</a>
        <a v-if="page<total" class="prev" :href="'./?page='+this.total">마지막</a>
      </div>

      <!-- <div id="paging">
        <a v-if="page>1" class="prev" :href="'./?page=1&select_table='+this.selectTable+'&target_word='+this.targetWord">처음</a>
        <a v-if="page>1" class="prev" :href="'./?page='+(this.page-1)+'&select_table='+this.selectTable+'&target_word='+this.targetWord"> ◀ 이전</a>
        <a v-for="i in paginationMake.list" :key="i" class="num" :href="'./?page='+i+'&select_table='+this.selectTable+'&target_word='+this.targetWord">{{i}}</a>
        <a v-if="page<total" class="prev" :href="'./?page='+(this.page+1)+'&select_table='+this.selectTable+'&target_word='+this.targetWord">다음 ▶ </a>
        <a v-if="page<total" class="prev" :href="'./?page='+this.total+'&select_table='+this.selectTable+'&target_word='+this.targetWord">마지막</a>
      </div> -->
    </div>

    <div v-if="this.$store.state.adminMode">
      <img @click="onModal(true)" :src="require(`@/assets/icons/plus-circle-fill.svg`)" class="icon-plus-upper">
    </div>

    <div v-if="this.$store.state.adminMode">
      <img @click="goInsertPage()" :src="require(`@/assets/icons/upc-scan.svg`)" class="icon-plus">
    </div>

  </div>

</template>

<script>

import axios from 'axios';

export default {
  name: 'PaginatedList',
  data () {
    return {
      isLoading: false,
      options: [
                {text: '제목', value: 'title'},
                {text: '저자', value: 'author'},
                {text: '출판사', value: 'publisher'},
                {text: 'ISBN', value: 'isbn'},
                ],
      setOption:'title',
      page: 1,
      listData: [],
      bookList: [],
      search:'',
      searchData: [],
      total: 1,
      pre:0,
      next:0,
      limit: 12,
      block: 5,
      searchedTotalPage: 0,
      modalStat: false,
      locationOption: [],
      setLocationOption: '',
      tempISBN: '',
    }
  },
  created(){
    this.getList();
  },
  mounted(){
    this.getParameter();
    this.getTotal();
    this.getPreNext()
  },
  updated(){
      this.getParameter();
      this.getTotal();
      this.getPreNext()
  },
  methods: {
    async getList(){

      this.isLoading = true;
      // this.listData = await this.$api('https://97b419b6-0d10-4926-984c-11e10ef1540c.mock.pstmn.io/test', "GET");
      this.listData = await this.$api('https://libraryapi.yirah.kr/paging', "GET");
      // console.log(this.bookList);

      this.getApi = await this.$api('https://libraryapi.yirah.kr/register', "GET");
      this.locationOption = this.getApi.location;
      this.setLocationOption = String(this.locationOption[0].id);

      this.bookList = this.listData;

      this.searchedTotalPage = this.bookList.length;
      this.isLoading = false;
    },
    goToDetail(id){
      this.$router.push({path:'/detail', query:{id:id}});
    },
    getTotal(){
      this.total = Math.ceil(this.bookList.length/this.limit);
    },
    async getParameter(){
      if(this.$route.query.page==null){
        this.page = 1;
      } else{
        this.page = this.$route.query.page;
      }
    },
    goInsertPage(){
      window.location.replace("https://yirah.kr/yirahstudy/insertbarcode.html");
    },
    async sendRegister(){

      let isbn = this.tempISBN;
      let locaid = parseInt(this.setLocationOption);

      let url = 'https://libraryapi.yirah.kr/register';
      let data = {
        isbn: isbn,
        locaid: locaid,
      }

      await axios.post(url, JSON.stringify(data), {
        headers: {
          "Content-Type": `application/json`,
        },
      }).catch(function (error) {
        console.log(error);
      });

      this.modalStat = false;
      this.$router.go();

    },
    onModal(state){
      this.modalStat = state;
    },
    onChange(event) {
      this.setOption = event.target.value;
    },
    onChangeInModal(event){
      this.setLocationOption = event.target.value;
    },
    // 일부 페이지에서 문자열 연산으로 페이징 하는 현상 수정
    getPreNext(){
    
      let number = this.page;
      number *= 1;

      this.pre = number-1;
      this.next = number+1;
  
    },
    checkImage(isbn){
      if(isbn == null){
        return false;
      } else{
        return true;
      }
    },
  },
  computed: {

    paginationMake () {

        let listLeng = this.bookList.length,
            listSize = this.limit,
            page = Math.floor(listLeng / listSize);
        if (listLeng % listSize > 0) page += 1;

        page;

        const total = this.bookList.length
        const totalPage = Math.ceil(total / this.limit)
        let currentPage = this.page
        const first =
          currentPage > 1 ? parseInt(currentPage, 10) - parseInt(1, 10) : null
        const end =
          totalPage !== currentPage
            ? parseInt(currentPage, 10) + parseInt(1, 10)
            : null
 
        
        let startIndex = (Math.ceil(currentPage / this.block) - 1) * this.block + 1
        let endIndex =
          startIndex + this.block > totalPage ? totalPage : startIndex + this.block - 1
        let list = []

        for (let index = startIndex; index <= endIndex; index++) {
          list.push(index)
        }

        return { first, end, list, currentPage }
    },
    paginatedData () {
      const start = (this.page-1) * this.limit,
            end = start + this.limit;

      let searchData = [];

      if(this.search&&this.setOption=="title"){
        searchData = this.bookList.filter((data)=> {
          return data.title.toLowerCase().includes(this.search.toLowerCase())
        });

        return searchData;

      } else if(this.search&&this.setOption=="author"){

        searchData = this.bookList.filter((data)=> {
          return data.author.toLowerCase().includes(this.search.toLowerCase())
        });

        return searchData;

      } else if(this.search&&this.setOption=="publisher"){

        searchData = this.bookList.filter((data)=> {
          return data.publisher.toLowerCase().includes(this.search.toLowerCase())
        });

        return searchData;

      } else if(this.search&&this.setOption=="isbn"){

        searchData = this.bookList.filter((data)=> {
          return data.isbn.toLowerCase().includes(this.search.toLowerCase())
        });

        return searchData;

      } else{
        // 검색어가 없다면 전체

        return this.bookList.slice(start, end);
      }
      
    },
  }
}

</script>