<template>
  
  <!-- <div v-if="data()"> -->
    <div class="detail-info">
        <div class="info-img">
            <img :src="'/img/'+content.isbn+'.jpg'">
        </div>
        
        <div class="info-txt">
            <div class="info-title">
                <h5>{{content.title}}</h5>
                <hr>
                <p>{{content.origintitle}}</p>
            </div>
            <p>{{content.author}} | {{content.translator}}</p>
            <p>{{content.pubdate}} | {{content.publisher}} | {{content.isbn}}</p>
            <p>{{content.disstat}}</p>
        </div>
    </div>

    <div class="detail-edit" v-if="this.$store.state.adminMode">
      <img @click="sendEvent()" :src="require(`@/assets/icons/pencil-square.svg`)" class="icon-pencil-square">
    </div>
    
    <div style="display: flex; justify-content:center;">
        <div id="map" class="map"></div>
    </div>

  <!-- </div> -->

</template>

<script>

// import dotenv from 'dotenv'

export default {
    name: 'detail-contents',
    props:{
      content:{
        type: Array,
        required: true,
      }
    },
    mounted() {
      // console.log(this.content);
      // window.kakao && window.kakao.maps
      //   ? this.initMap(this.content.latitude, this.content.longitude)
      //   : this.addKakaoMapScript();
    },
    updated(){
      // console.log(this.content);
      window.kakao && window.kakao.maps
        ? this.initMap(this.content.latitude, this.content.longitude)
        : this.addKakaoMapScript();
    },
    methods: {

    addKakaoMapScript() {

      // API KEY 숨기기 위한 조치
      // dotenv.config();
      const VUE_APP_KAKAO = process.env.VUE_APP_KAKAO;

      const script = document.createElement("script");
      /* global kakao */
      script.onload = () => kakao.maps.load(this.initMap);
      script.src =
        `//dapi.kakao.com/v2/maps/sdk.js?appkey=${VUE_APP_KAKAO}&autoload=false&libraries=services,clusterer,drawing`;
      document.head.appendChild(script);
    },
    initMap(lat, long) {
      var container = document.getElementById("map"); //지도를 담을 영역의 DOM 레퍼런스

    //   var options = {
    //     //지도를 생성할 때 필요한 기본 옵션
    //     center: new kakao.maps.LatLng(37.5081852743285, 127.032836328523), //지도의 중심좌표.
    //     level: 3 //지도의 레벨(확대, 축소 정도)
    //   };
      var options = {
        //지도를 생성할 때 필요한 기본 옵션
        center: new kakao.maps.LatLng(lat, long), //지도의 중심좌표.
        level: 3 //지도의 레벨(확대, 축소 정도)
      };

      var map = new kakao.maps.Map(container, options); //지도 생성 및 객체 리턴
      //   map.setMapTypeId(kakao.maps.MapTypeId.HYBRID); //위성사진 활용

      //   var imageSrc = 'https://t1.daumcdn.net/localimg/localimages/07/mapapidoc/marker_red.png', // 마커이미지의 주소입니다    
          //   imageSize = new kakao.maps.Size(64, 69), // 마커이미지의 크기입니다
          //   imageOption = {offset: new kakao.maps.Point(27, 69)}; // 마커이미지의 옵션입니다. 마커의 좌표와 일치시킬 이미지 안에서의 좌표를 설정합니다.
      
      // 마커의 이미지정보를 가지고 있는 마커이미지를 생성합니다
      // var markerImage = new kakao.maps.MarkerImage(imageSrc, imageSize, imageOption)
      
      // 마커가 표시될 위치입니다 
      var markerPosition  = new kakao.maps.LatLng(lat, long); 
      //   var markerPosition  = new kakao.maps.LatLng(37.5081852743285, 127.032836328523); 
      
      // 마커를 생성합니다
      var marker = new kakao.maps.Marker({
            position: markerPosition
      });

      container.parentNode.style.marginTop = '1.8rem';

      // 이미지 사용하는 경우의 마커 생성
      //     var marker = new kakao.maps.Marker({
      //     position: markerPosition, 
      //     image: markerImage // 마커이미지 설정 
      // });

      // 마커가 지도 위에 표시되도록 설정합니다
      marker.setMap(map);
    },
    // 이미지 로드 중 null에 의한 오류 발생 제어
    checkImage(){
      if(this.content.isbn == null){
        return false;
      } else{
        return true;
      }
    },
    sendEvent(){
      this.$emit('update');
    },
  },
    
}

</script>
