import Vuex from 'vuex'

import createPersistedState from 'vuex-persistedstate';

const store = new Vuex.Store({
    state(){
        return{
            adminMode: false,
        }
    
    },
    mutations: {
        makeTrue(state){
			state.adminMode = true;
		},
        makeFalse(state){
            state.adminMode = false;
        },
    },
    plugins: [
        createPersistedState({
            // store를 session storage에 유지
            storage: window.sessionStorage,
        })
        // persistedstate({
        //     paths: ['user']
        // })
    ]
});

export default store;