<template>

  <div id="loading" v-if="isLoading">
    <img :src="require(`@/assets/loading.gif`)" alt="loading">
  </div>

  <!-- content -->
  <div class="content">

    <div class="table-content">
        <div class="table-content-inner">
            <div class="cardtr" v-for="list in paginatedData" :key="list.id">
                <div class="cardtr-inner">
                    <div class="checkboxtd">
                        <div class="checkbox-inner">
                            <input class="form-check-input" id="flexCheckDefault" type="checkbox" name="check" :value="list.id">
                        </div>
                    </div>
                    <div class="transfer-titletd">
                        <div class="titletd-inner">
                            <div>{{list.title}}</div>
                            <div>{{list.isbn}}</div>
                        </div>
                    </div>
                    <div class="transfer-addresstd">
                        <div class="addresstd-inner">
                            {{list.address}}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    
	<div class="notion">
		<h5>총 {{searchedTotalPage}}개의 데이터가 검색되었습니다</h5>
	</div>

	<div class="changer">
		<div>
			<input class="form-check-input" id="flexCheckAll" type="checkbox" name="checkAll" :value="selectAll" @click="onCheck()">
			<label class="form-check-label" for="flexCheckAll">전체선택</label>
		</div>
		<div class="transfer-button-wrapper">
			<select @change="onChange($event)">
				<option v-for="option in options" :key="option" :value="option.id">{{option.address}}</option>
			</select>
			<button class="transfer-button" @click="setTransfer()">변경</button>
		</div>
	</div>

	<div id="paging">
	<a v-if="page>1" class="prev" :href="'./transfer?page=1'">처음</a>
	<a v-if="page>1" class="prev" :href="'./transfer?page='+this.pre">◀ 이전</a>
	<a v-for="i in paginationMake.list" :key="i" class="num" :href="'./transfer?page='+i">{{i}}</a>
	<a v-if="page<total" class="prev" :href="'./transfer?page='+this.next">다음 ▶</a>
	<a v-if="page<total" class="prev" :href="'./transfer?page='+this.total">마지막</a>
	</div>

  <div v-if="this.$store.state.adminMode">
    <img @click="goTransferPage()" :src="require(`@/assets/icons/upc-scan.svg`)" class="icon-plus">
  </div>

  </div>

</template>

<script>

import axios from 'axios';
// import IconPencilSquare from '../components/icons/IconPencilSquare.vue'
// import IconTrash from '../components/icons/IconTrash.vue'
// import IconPlus from '../components/icons/IconPlus.vue'

export default {
  name: 'Transfer',
  data () {
    return {
      isLoading: false,
      options: [],
      setOption:'',
      page: 1,
      getApi: [],
      location: [],
      bookList: [],
      search:'',
      searchData: [],
      total: 1,
      pre:0,
      next:0,
      limit: 20,
      block: 5,
      searchedTotalPage: 0,
    }
  },
//   components:{
//       IconPencilSquare,
//       IconTrash,
//       IconPlus,
//   },
  created(){
    this.getList();
  },
  mounted(){
    this.getParameter();
    this.getTotal();
    this.getPreNext()
  },
  updated(){
      this.getParameter();
      this.getTotal();
      this.getPreNext()
  },
  methods: {
    async getList(){
      
      this.isLoading = true;
      this.getApi = await this.$api('https://libraryapi.yirah.kr/transfer', "GET");
      this.options = this.getApi.location;

      this.bookList = this.getApi.transfer;
      this.searchedTotalPage = this.bookList.length;
      this.setOption = String(this.options[0].id);
      this.isLoading = false;
    },
    getTotal(){
      this.total = Math.ceil(this.bookList.length/this.limit);
    },
    async getParameter(){
      if(this.$route.query.page==null){
        this.page = 1;
      } else{
        this.page = this.$route.query.page;
      }
    },
    goTransferPage(){
      window.location.replace("https://yirah.kr/yirahstudy/transferbarcode.html");
    },
    onChange(event) {
      this.setOption = event.target.value;
    },
    onCheck(){

      let checkAll = document.getElementById('flexCheckAll').checked;
      let checkboxes = document.getElementsByName('check');

      if(checkAll){
        checkboxes.forEach((checkbox) => {
            checkbox.checked = true;
        })
      } else{
        checkboxes.forEach((checkbox) => {
            checkbox.checked = false;
        })
      }

    },
    // onStatChange(event) {
    //   this.tempFindStat = parseInt(event.target.value);
    // },
    // 일부 페이지에서 문자열 연산으로 페이징 하는 현상 수정
    getPreNext(){
    
      let number = this.page;
      number *= 1;

      this.pre = number-1;
      this.next = number+1;
  
    },
    async setTransfer(){

		let checkedIds = []
        let checkboxes = document.getElementsByName('check');
		for(let i=0;i<checkboxes.length;i++){
			if(checkboxes[i].checked == true){
				checkedIds.push(checkboxes[i].value);
			}
		}

        let option = this.setOption;

        let url = 'https://libraryapi.yirah.kr/transfer';
        let data = {
            transferId:checkedIds,
            location:option,
        };

        await axios.put(url, JSON.stringify(data), {
          headers: {
            "Content-Type": `application/json`,
          },
        }).catch(function (error) {
            console.log(error);
        }).then(() => {
            alert('변경 완료했습니다.');
        });

		this.$router.go();
    },
  },
  computed: {

    paginationMake () {

        let listLeng = this.bookList.length,
            listSize = this.limit,
            page = Math.floor(listLeng / listSize);
        if (listLeng % listSize > 0) page += 1;

        page;

        const total = this.bookList.length
        const totalPage = Math.ceil(total / this.limit)
        let currentPage = this.page
        const first =
          currentPage > 1 ? parseInt(currentPage, 10) - parseInt(1, 10) : null
        const end =
          totalPage !== currentPage
            ? parseInt(currentPage, 10) + parseInt(1, 10)
            : null
 
        
        let startIndex = (Math.ceil(currentPage / this.block) - 1) * this.block + 1
        let endIndex =
          startIndex + this.block > totalPage ? totalPage : startIndex + this.block - 1
        let list = []

        for (let index = startIndex; index <= endIndex; index++) {
          list.push(index)
        }

        return { first, end, list, currentPage }
    },
    paginatedData () {
      const start = (this.page-1) * this.limit,
            end = start + this.limit;

      return this.bookList.slice(start, end);
      
    },
  }
}

</script>