<template>
  <div>

    <Sidebar />
    <Header />
    <router-view/>
    <Footer />

  </div>
</template>

<script>
  import Header from './layouts/Header'
  import Sidebar from './layouts/Sidebar'
  import Footer from './layouts/Footer'
  // import PaginatedList from './views/PaginatedList'

  export default {
    name: 'App',
    // components: {Header, Sidebar, Footer, PaginatedList},
    components: {Header, Sidebar, Footer},
    data(){
      return{
        modalstat: false,
        pageNum: 0,
        bookList: []
      };
    },
    created(){
        // this.getList();
    },
    mounted(){
        // this.getList();
    },
    methods : {
      optionSelect(){
        
      },
      async getList(){
        // this.bookList = await this.$api('https://97b419b6-0d10-4926-984c-11e10ef1540c.mock.pstmn.io/test', "GET");
        // this.bookList = await this.$api('http://203.245.28.80:5001/paging', "GET");
        // console.log(this.bookList);
      },
      modalOpen(){
        this.modalstat = true;
      },
      modalClose(){
        this.modalstat = false;
      }
    },
  }
</script>

<style>
body{
    margin:3px;
    padding:0;
}

#app {
  /* font-family: Avenir, Helvetica, Arial, sans-serif; */
  width: 100%;
  height: 100%;
  font-family: 'Noto Sans KR', 'Noto Serif KR';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

@import './assets/style/laptop.css';
@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@500&family=Noto+Serif+KR:wght@300&display=swap');


</style>
