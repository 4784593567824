<template>
    <div class="header">
      <div class="menu">
        <!-- <a href="" v-for="menu in menus" :key="menu">{{menu}}</a> -->
        <!-- <a href='./'>찾아보기</a>
        <a href='./insert'>추가하기</a>
        <a href='./seek'>구하기</a> -->
        <router-link to="/">찾아보기</router-link>
        <!-- <router-link to="/yirahstudy_test">찾아보기</router-link> -->
        <router-link to="/seek">구하기</router-link>
        <router-link to="/transfer">이동하기</router-link>
      </div>
      <div class="admin">
        <img @click="onModal(true)" :src="require(`@/assets/icons/person-circle.svg`)" alt="admin">
      </div>
    </div>

      <!-- modal -->
    <div v-if="onModalchk">
      <div class="black-bg">
          <div class="white-bg">
              <h4 class="modal-title">인증</h4>

              <div class="modal-inner">
                  <div>
                      <label for="password">코드</label>
                      <input type="password" class="modal-input" v-model="tempPassword"/>
                  </div>
                  <div class="modal-button-wrapper">
                      <button class="modal-button" @click="checkAdmin()">AUTH</button>
                      <button class="modal-button" @click="onModal(false)">CLOSE</button>
                  </div>
              </div>
          </div>
      </div>
    </div>
</template>

<script>

import axios from 'axios';
import crypto from "crypto";

export default {
  name: 'Header',
  data(){
    return {
      menus: ['찾아보기', '구하기', '이동하기'],
      onModalchk: false,
      tempPassword: '',
    }
  },
  methods: {
    onModal(checker){

      if(checker){
        this.onModalchk = true;
      } else{
        this.onModalchk = false;
        this.tempPassword = '';
      }
    },
    async checkAdmin(){

      const base64crypto = crypto.createHash('sha512').update('&tr$ee*'+this.tempPassword+'azvxeyirah@#$%').digest('base64');

      let url = 'https://libraryapi.yirah.kr/auth';
      let data = {
          code: base64crypto,
      }

      await axios.post(url, JSON.stringify(data), {
        headers: {
          "Content-Type": `application/json`,
        },
      }).catch(function (error) {
          console.log(error);
      }).then((res) => {
        if(res.data.checkAdmin){
          this.$store.commit('makeTrue');
        } else{
          this.$store.commit('makeFalse');
        }
      });

      this.onModal(false);
    },
  },
}
</script>